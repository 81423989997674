.only-on-print {
  display: none !important;
}

.accent-lighting:hover {
	background-color: #cccccc22;
}


@media print {
	html, body{
		height: 297mm;
		width: 210mm;
	}

  .print-hidden {
		display: none !important;
	}

  .only-on-print {
    display: block !important;
  }
  
}

@page {
	margin: 20mm 10mm 20mm 20mm;
}